import _declaration from "./declaration";
import _previousMap from "./previous-map";
import _comment from "./comment";
import _atRule from "./at-rule";
import _input from "./input";
import _root from "./root";
import _rule from "./rule";
var exports = {};
let Declaration = _declaration;
let PreviousMap = _previousMap;
let Comment = _comment;
let AtRule = _atRule;
let Input = _input;
let Root = _root;
let Rule = _rule;
function fromJSON(json, inputs) {
  if (Array.isArray(json)) return json.map(n => fromJSON(n));
  let {
    inputs: ownInputs,
    ...defaults
  } = json;
  if (ownInputs) {
    inputs = [];
    for (let input of ownInputs) {
      let inputHydrated = {
        ...input,
        __proto__: Input.prototype
      };
      if (inputHydrated.map) {
        inputHydrated.map = {
          ...inputHydrated.map,
          __proto__: PreviousMap.prototype
        };
      }
      inputs.push(inputHydrated);
    }
  }
  if (defaults.nodes) {
    defaults.nodes = json.nodes.map(n => fromJSON(n, inputs));
  }
  if (defaults.source) {
    let {
      inputId,
      ...source
    } = defaults.source;
    defaults.source = source;
    if (inputId != null) {
      defaults.source.input = inputs[inputId];
    }
  }
  if (defaults.type === "root") {
    return new Root(defaults);
  } else if (defaults.type === "decl") {
    return new Declaration(defaults);
  } else if (defaults.type === "rule") {
    return new Rule(defaults);
  } else if (defaults.type === "comment") {
    return new Comment(defaults);
  } else if (defaults.type === "atrule") {
    return new AtRule(defaults);
  } else {
    throw new Error("Unknown node type: " + json.type);
  }
}
exports = fromJSON;
fromJSON.default = fromJSON;
export default exports;